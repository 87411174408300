/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://unpkg.com/tailwindcss/dist/base.css
 */
@tailwind base;

/**
 * Remove the default box-shadow for invalid elements to prevent
 * inputs in Livewire components showing with a
 * red border by default in Firefox.
 *
 * See: https://github.com/laravel-frontend-presets/tall/issues/7
 */
 input:invalid, textarea:invalid, select:invalid {
    box-shadow: none;
}

/**
 * This injects any component classes registered by plugins.
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 */

[x-cloak] {
    display: none;
}

.text-logo {
    letter-spacing: 0.281em;
}

.tracking-custom-1 {
    letter-spacing: 0.250em;
}

.left-custom-text {
    position: relative;
}

.left-custom-text:after {
    content: '';
    height: 1px;
    width: 120px;
    background:#fff;
    position: absolute;
    bottom: -25px;
    right: -50px;
    left: inherit;
}

.right-custom-text {
    position: relative;
}

.right-custom-text:before {
    content: '';
    height: 1px;
    width: 120px;
    background:#fff;
    position: absolute;
    bottom: -25px;
    right: -50px;
    left: inherit;
}

.logo {
    width: 135px;
}

.vertical-custom-text-1 {
    writing-mode: vertical-rl;text-orientation: mixed; transform:rotate(180deg);
}

.white-right-border {
    position: relative;
    transform: translate3d(2px, 0px, 0px);
}
.white-right-border:after {
    content: "";
    width: 2px;
    height: 100%;
    background: #fff;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// tailwindcss-aspect-ratio generates these
.aspect-ratio-none {
  padding-bottom: 0;
}
.aspect-ratio-square {
  padding-bottom: 100%;
}
.aspect-ratio-16\/9 {
  padding-bottom: 56.25%;
}
.aspect-ratio-4\/3 {
  padding-bottom: 75%;
}
.aspect-ratio-21\/9 {
  padding-bottom: 42.86%;
}


@media (min-width: 768px) {
    .left-custom-text {
        writing-mode: vertical-rl;text-orientation: mixed; transform:rotate(180deg);
    }
    .left-custom-text:after {
        content: '';
        width: 1px;
        height: 120px;
        background:#fff;
        position: absolute;
        bottom: -50px;
        left: -25px;
        right: inherit;
    }
    .right-custom-text {
        writing-mode: vertical-rl;text-orientation: mixed;
    }
    .right-custom-text:before {
        content: '';
        width: 1px;
        height: 120px;
        background:#fff;
        position: absolute;
        bottom: -50px;
        left: -25px;
        right: inherit;
    }
}


/* SVG Morph */
.morph-wrap {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
    pointer-events: none;
    opacity: 1;
}

.morph {
	position: relative;
	height: 100%;
	fill: var(--background-color-2);
	flex: none;
}

.right-hover {
    transform: translate3d(0,0,0);
}
.left-hover {
    transform: translate3d(0,0,0) rotate(180deg);
}
.right-hover-activate, .left-hover-activate {
    transition: transform ease-out 1s;
}
.right-hover-activate:before, .left-hover-activate:after  {
    transition: all ease-out 1s;
}
.right-hover:hover {
    transform: translate3d(0px, 10px, 0px);
}

.right-hover:hover:before {
    bottom: -30px;
}

.left-hover:hover {
    transform: translate3d(0px, -10px, 0px) rotate(180deg);
}

.left-hover:hover:after {
    bottom: -18px;
}


@media (max-width: 768px) {
    .left-hover {
        transform: translate3d(0,0,0);
    }
    .left-hover:hover {
        transform: translate3d(0px, -10px, 0px);
    }
}
